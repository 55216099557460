



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ExpansionPanel } from '@/types/cms/components';

/**
 * CMS expansion panel component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentExpansionPanel extends Vue {
    // Prop with expansion panels
    @Prop({ type: Array as () => ExpansionPanel[], required: true }) readonly expansionPanels: ExpansionPanel[] | undefined;
}
